
.ant-radio-button-wrapper.platform-button {
    background: transparent;
    color: #fff ;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.platform-button {
    background-color: white;
    color: #000;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled.platform-button {
    background-color: #BFBFBF;
    color: #fff;
}

.ant-modal-content {
    border-radius: 6px;
    border: 2px solid var(--neutral-1, #FFF);
    background: var(--orange-gradient, linear-gradient(114deg, #F58C31 -9.08%, #F76C1C -9.07%, #F68C34 28.36%, #FAB44C 70.57%, #F9D152 105.05%));
    box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.50);
}

.success-redeem-modal .ant-modal-content {
    border-radius: 6px;
    border: 2px solid var(--neutral-1, #FFF);
    background: white !important;
    box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.50);
}

.button-confirm {
    width: 80% !important;
    height: 50px !important;
    border-radius: 4px;
    box-shadow: 0px 4px 6px 0px #E58536;
}

.button-success-redeem {
    width: 80% !important;
    height: 70px !important;
    border-radius: 6px;
}

.button-redeem {
    width: 100% !important;
    height: 50px !important; 
    background: var(--orange-gradient, linear-gradient(114deg, #F58C31 -9.08%, #F76C1C -9.07%, #F68C34 28.36%, #FAB44C 70.57%, #F9D152 105.05%));
    box-shadow: 0px 0px 8px 0px rgba(241, 241, 241, 0.60);
}

.button-back {
    width: 100% !important;
    height: 50px !important; 
    background: var(--orange-gradient, linear-gradient(114deg, #F58C31 -9.08%, #F76C1C -9.07%, #F68C34 28.36%, #FAB44C 70.57%, #F9D152 105.05%));
    box-shadow: 0px 0px 8px 0px rgba(241, 241, 241, 0.60);
}