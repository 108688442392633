body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1F1F1F;
  color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-item-selected {
  background: linear-gradient(
    270deg,
    #024567 0%,
    rgba(2, 69, 103, 0) 100%
  ) !important;
  border-right: 2px solid #35e49d !important;
}

.ant-empty-description {
  color: #fff !important;
}

.ant-pro-card {
  border: 1px solid var(--Grey-button, #e0e0e0);
  border-radius: 6px;
}

.ant-table-thead .ant-table-cell {
  background-color: white !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 1px solid #a6a6a6;
}

.custom-table-header {
  /* Styles for table header cells */
  .ant-table-thead .ant-table-cell {
    background-color: #f8f8f8 !important;
  }
  .ant-pro-card .ant-pro-card-body {
    padding-inline: 0px !important;
  }
}

.ant-dropdown-trigger {
  color: #fff !important;
}

.ant-button .platform-button:focus{
  background-color: white;
  color: #000;
}
.ant-button .platform-button:hover {
  border-color: white;
  color: white;
}
.ant-carousel {
  width: 80%;
}

.default-button {
  width: 100%; 
  height: 50px !important; 
  background: var(--orange-gradient, linear-gradient(114deg, #F58C31 -9.08%, #F76C1C -9.07%, #F68C34 28.36%, #FAB44C 70.57%, #F9D152 105.05%));
  box-shadow: 0px 0px 8px 0px rgba(241, 241, 241, 0.60);
}

.custom-validate-color .ant-form-item-explain-error{
  color: red;
}